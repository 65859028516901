import React, {useState, useRef} from 'react';
import {AnimationSlideToggle} from '.';

const CustomDropDown = ({items, label, onClickOption}) => {

    const [selectedOption, setSelectedOption] = useState(items[0]);
    const [isOpen, setIsOpen] = useState(false);

    const handleClickOption = option => {
        setSelectedOption(option);
        onClickOption(option);
        setIsOpen(false);
    }

    return (
        <div className={"custom-dropdown" + (label ? " with-label" : "") + (isOpen ? " active" : "")}>
            <label>{label}</label>
            <div className="selected" onClick={() => setIsOpen(!isOpen)}>
                <div className="selected-option">{selectedOption.name}</div>
            </div>
            <AnimationSlideToggle isOpen={isOpen}>
                <ul>
                    {items && items.map(option => {
                        if (option.id !== selectedOption.id) {
                            return (
                                <li key={`option-${option.id}`} onClick={() => handleClickOption(option)}>
                                    {option.name}
                                </li>
                            )
                        }
                    })}
                </ul>
            </AnimationSlideToggle>
        </div>
    )
}

export default CustomDropDown;