import { useContext } from "react";
import { MaintenanceContext } from "../context";

const useMaintenanceData = () => {

  const [state, setState] = useContext(MaintenanceContext);

  const getModels = () => {
    return state.models ? state.models : [];
  }

  const getSelectedModel = () => {
    return state.selectedModel ? state.selectedModel : null;
  }

  const getSelectedMaintenancePlan = () => {
    return state.selectedMaintenancePlan ? state.selectedMaintenancePlan : null;
  }

  const getLoading = () => {
    return state.loading;
  }

  const setSelectedModel = model => {
    setState(prevState => ({
      ...prevState,
      selectedModel: model,
      selectedMaintenancePlan: model.maintenance_plans[0]
    }));
  }

  const setSelectedMaintenancePlan = maintenancePlan => {
    setState(prevState => ({
      ...prevState,
      selectedMaintenancePlan: maintenancePlan
    }));
  }


  return {
    getModels,
    getSelectedModel,
    getSelectedMaintenancePlan,
    getLoading,
    setSelectedModel,
    setSelectedMaintenancePlan
  }

}


export default useMaintenanceData;
