import React from 'react';
import CustomDropDown from '../../utils/CustomDropDown';

import useMaintenanceData from '../hooks/useMaintenanceData';

const ModelSelector = () => {

    const {getModels, getSelectedModel, setSelectedModel} = useMaintenanceData();

    const models = getModels();
    const selectedModel = getSelectedModel();

    const handleChangeModel = model => {
        setSelectedModel(model);
    }

    return (
        <div className="model-selector">
            <CustomDropDown items={models} onClickOption={handleChangeModel} />
            <div className="selected-model">
                { selectedModel.image ? (
                    <img src={selectedModel.image.url} alt={selectedModel.image.alt} />
                ) : null}
            </div>
        </div>
    )
}

export default ModelSelector;