import React, {useState} from 'react';
import {AnimationSlideToggle} from '.';

const Accordion = ({title, children}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
            <div className={"accordion " + (isOpen ? "active" : "")}>
                <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
                    <h4>{title}</h4>
                </div>
                <AnimationSlideToggle isOpen={isOpen}>
                    <div className="accordion-body">
                        {children}
                    </div>
                </AnimationSlideToggle>
            </div>
    )
}

export default Accordion;