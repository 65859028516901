import React, {useState, useEffect, useRef} from 'react';



const InputRange = ({title, subtitle, defaultValue, minValue, maxValue, step, valueText, valueTextMax, suffix, prefix, minExtra, maxExtra, onChangeValue}) => {

    const [currentValue, setCurrentValue] = useState(defaultValue ? defaultValue : minValue);
    const inputRange = useRef(null);
    
    useEffect(() => {
        rangeSlider();
    }, [currentValue])

    useEffect(() => {
        setCurrentValue(defaultValue ? defaultValue : minValue);
    }, [minValue, maxValue,defaultValue])

    const rangeSlider = () => {
        const wrap = inputRange.current;
        const range = wrap.querySelector(".range");
        const bubble = wrap.querySelector(".bubble");

        range.addEventListener("input", () => {
            setBubble(range, bubble);
        });
        setBubble(range, bubble);
    }

    const setBubble = (range, bubble) => {
        const val = range.value;
        const min = range.min ? range.min : 0;
        const max = range.max ? range.max : 100;
        let newVal = Number(((val - min) * 100) / (max - min));
        newVal = Number.isNaN(newVal) ? 0 : newVal;
        bubble.innerHTML = (prefix || '') + unitFormat(val) + (suffix || '');
        // Sorta magic numbers based on size of the native UI thumb
        bubble.style.left = `calc(${newVal}% + (${18 - newVal * 0.35}px))`;
    }

    const changeValue = value => {
        setCurrentValue(value);
        onChangeValue(value);
    }

    const stepForward = () => {
        if (currentValue < maxValue) changeValue(currentValue + step );
    }

    const stepBackward = () => {
        if (currentValue > minValue) changeValue(currentValue - step);
    }

    const unitFormat = (value) => {
        if (!value) return '0';
        const val = parseInt(value);
        return val.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    return (
        <div className="input-range-container">
            {title && <h2>{title}</h2>}
            {subtitle && <p>{subtitle}</p>}
            <div className="input-range-body">
                <div className="info">
                    <div className="info-left">
                        <small>{valueText}</small>
                        <span className="unit">{prefix}{unitFormat(minValue)}{suffix}<small><sup>{minExtra}</sup></small></span>
                    </div>
                    <div className="info-right">
                        <small>{valueTextMax ? valueTextMax : valueText}</small>
                        <span className="unit">{prefix}{unitFormat(maxValue)}{suffix}<small><sup>{maxExtra}</sup></small></span>
                    </div>
                </div>
                <div className="input-range">
                    <i className="icon chevron-input-range" onClick={stepBackward}></i>
                    <div className="range-wrap" ref={inputRange}>
                        <input className="range" type="range" onTouchEnd={(e) => onChangeValue(e.target.value)} onMouseUp={(e) => onChangeValue(e.target.value)} onChange={(e) => setCurrentValue(e.target.value)} value={currentValue} step={step} min={minValue} max={maxValue} />
                        <output className="bubble"></output>
                    </div>
                    <i className="icon chevron-input-range right" onClick={stepForward}></i>
                </div>                    
            </div>
        </div>
    )
}

InputRange.defaultProps = {
    title: '', 
    subtitle: '', 
    defaultValue: 0, 
    minValue: 0, 
    maxValue: 10, 
    step: 1, 
    valueText: '', 
    valueTextMax: '', 
    suffix: '', 
    prefix: '', 
    minExtra: '', 
    maxExtra: '', 
    onChangeValue: () => {},
}

export default InputRange;