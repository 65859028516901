import React, {useState, useEffect} from 'react';
import ModelSelector from '../components/ModelSelector';
import InputRange from '../../utils/InputRange';
import UnderlineTitle from '../../utils/UnderlineTitle';
import Accordion from '../../utils/Accordion';
import Legals from '../components/Legals';
import {CurrencyFormat} from '../../utils';

import useMaintenanceData from '../hooks/useMaintenanceData';

const MaintenancePlan = () => {

    const { getSelectedMaintenancePlan, getSelectedModel, getLoading, setSelectedMaintenancePlan} = useMaintenanceData();

    const selectedMaintenancePlan = getSelectedMaintenancePlan();
    const selectedModel = getSelectedModel();
    const loading = getLoading();

    useEffect(() => {
        getMaxAndMinValues();
    }, [selectedModel])

    const [inputRangeProperties, setInputRangeProperties] = useState({
        title: "Seleccione un kilometraje",
        subtitle: "Los valores expresados son cada 10.000 kilómetros de uso normal.",
        valueText: "Kilómetros",
        minValue: 10000,
        maxValue: 10000,
        step: 10000,
        prefix: '',
        minExtra: '',
        maxExtra: '(1)'
    })

    const handleChangeInputRange = value => {
        let maintenancePlan = selectedModel.maintenance_plans.find(plan => plan.kilometers == value);
        setSelectedMaintenancePlan(maintenancePlan);
    }

    const getMaxAndMinValues = () => {
        if (selectedModel) {
            let ordered = selectedModel.maintenance_plans.sort((a,b)=>a.kilometers - b.kilometers);
            setInputRangeProperties(prevState => ({
                ...prevState,
                minValue: parseInt(ordered[0].kilometers),
                maxValue: parseInt(ordered[ordered.length - 1].kilometers)
              }));
        }
    }

    return (
        <>
        {!loading ? 
            <div className="full-viewport-width">
                <div className="main-container">
                    <h1>Elige tu Toyota y mira su Plan de Mantención</h1>
                    <ModelSelector />
                    <InputRange {...inputRangeProperties} onChangeValue={handleChangeInputRange}/>
                    <UnderlineTitle title={"Servicios de Mantención"}/>
                    {selectedMaintenancePlan && selectedMaintenancePlan.revision &&
                        <Accordion title={"Realizar"}>
                            <div className="maintenance-plan-list" dangerouslySetInnerHTML={{ __html: selectedMaintenancePlan.revision}}></div>
                        </Accordion>
                    }
                    {selectedMaintenancePlan && selectedMaintenancePlan.inspection &&
                        <Accordion title={"Inspección"}>
                            <div className="maintenance-plan-list" dangerouslySetInnerHTML={{ __html: selectedMaintenancePlan.inspection}}></div>
                        </Accordion>
                    }
                    {selectedMaintenancePlan && selectedMaintenancePlan.change &&
                        <Accordion title={"Cambio"}>
                            <div className="maintenance-plan-list" dangerouslySetInnerHTML={{ __html: selectedMaintenancePlan.change}}></div>
                        </Accordion>
                    }
                    {selectedMaintenancePlan &&
                    <UnderlineTitle title={"Precio de Mantención"}>
                        <span className="maintenance-price">{CurrencyFormat(selectedMaintenancePlan.price)}<small>(*)</small></span>
                    </UnderlineTitle>
                    }           
                </div>
            </div>
        : 
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        }
        </>

    );
}

export default MaintenancePlan;
