import React from 'react';

const UnderlineTitle = ({title, children, small}) => {

    return (
        <div className="underline-title">
            {small ? <h4>{title}</h4> : <h3>{title}</h3>}
            <div className="right-element">
                {children}
            </div>
        </div>
    )
}

export default UnderlineTitle;