import React, { useState, useEffect } from "react";

import Axios from 'axios';

const initialContext = {
    models: [],
    selectedModel: null,
    selectedMaintenancePlan: null,
    loading: true
}

const MaintenanceContext = React.createContext([{}, () => {}]);

const MaintenanceProvider = ({children}) => {
    const [state, setState] = useState(initialContext);

    useEffect(() => {
        Axios.get(`/api/vehicles/models/maintenance_plans`)
        .then(response => {
            const models = response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).filter(model => model.maintenance_plans.length > 0);
            setState({
                models: models,
                selectedModel: models[0],
                selectedMaintenancePlan: models[0].maintenance_plans[0]
            });
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
        });
    }, [])

    return <MaintenanceContext.Provider value={[state, setState]}>{children}</MaintenanceContext.Provider>;
};

export { MaintenanceContext, MaintenanceProvider, initialContext };
