import React from 'react';
import MaintenancePlan from './views/MaintenancePlan';
import { MaintenanceProvider } from "./context";

const App = () => {
    return (
        <MaintenanceProvider >
            <MaintenancePlan />
        </MaintenanceProvider>
    );
}

export default App;